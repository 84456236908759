import React from 'react';
import Typography from '../../Components/Atoms/Typography';
import Box from '../../Components/Atoms/Box';
import './styles.scss';

const Footer = () => {
  return (
    <Box className='footer'>
      <Box className='inner-footer'>
        <Box className='flex-column' style={{ marginRight: '16px' }}>
          <img src='/logo-b-and-w.JPG' alt='gastronaut logo' />
          <Typography variant='text-4' color='subdued'>
            ©2021 Gastronaut GmbH. All rights reserved
          </Typography>
        </Box>
        <Box className='section contact'>
          <Typography variant='h4' translation='footer'>
            Contact us
          </Typography>
          <Box className='list' flex>
            <Typography block className='list-element' variant='text-3'>
              mail@gastronaut.ai
            </Typography>
            <Typography block className='list-element' variant='text-3'>
              +49 1525 4051666
            </Typography>
            <Typography block className='list-element' variant='text-3'>
              Bahnhofstraße 55, 69115 Heidelberg Germany
            </Typography>
          </Box>
        </Box>
        <Box className='section links'>
          <Typography variant='h4' translation='footer'>
            Useful links
          </Typography>
          <Box className='list'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://help.jimdo.com/hc/en-us'
            >
              <Typography
                translation='footer'
                block
                className='list-element'
                variant='text-3'
              >
                Jimdo Support
              </Typography>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://university.webflow.com/support'
            >
              <Typography
                translation='footer'
                block
                className='list-element'
                variant='text-3'
              >
                Webflow Support
              </Typography>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://wordpress.com/support/'
            >
              <Typography
                translation='footer'
                block
                className='list-element'
                variant='text-3'
              >
                WordPress Support
              </Typography>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://support.wix.com/en/'
            >
              <Typography
                translation='footer'
                block
                className='list-element'
                variant='text-3'
              >
                Wix Support
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
