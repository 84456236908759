import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

export const integrationStepsWf: IntegrationSteps = [
  {
    number: 1,
    step:
      'Copy the snippet code from your profile or from the box on top of this page.',
  },
  {
    number: 2,
    step:
      'In your Webflow editor, press A to open the Add elements panel. It opens a sidebar to the left of the screen.',
  },
  {
    number: 3,
    step:
      'Inside the panel, scroll down to the Components,section. Choose the Embed element from the components section. You can drag it where you want it to be displayed.',
    img: '/doc-steps-imgs/overlaybtn/webflow/step-3.JPG',
  },
  {
    number: 4,
    step:
      'Paste in the custom code you copied in step 1 in the modal that just opened. You can allow the line wrapping of the code thanks to a bottom left checkbox.',
  },
  {
    number: 5,
    step: 'When the code is pasted, click on Save and close.',
    img: '/doc-steps-imgs/overlaybtn/webflow/step-4-5.JPG',
  },
  {
    number: 6,
    step:
      'If the code snippet starts with the <script> tag, the box in the editor will mention that the element will only be visible when the website is published. If it starts with <iframe>, it should be visible on the editor view.',
  },
];

const OverlayBtnIntegrationStepsWf: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWf} />
    </Box>
  );
};

export default OverlayBtnIntegrationStepsWf;
