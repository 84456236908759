import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const OverlayBtnTileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='1'
          y='1'
          width='8'
          height='8'
          rx='1'
          stroke='#14142B'
          strokeWidth='2'
        />
        <rect
          x='15'
          y='1'
          width='8'
          height='8'
          rx='1'
          stroke='#14142B'
          strokeWidth='2'
        />
        <rect
          x='1'
          y='15'
          width='8'
          height='8'
          rx='1'
          stroke='#14142B'
          strokeWidth='2'
        />
        <rect
          x='15'
          y='15'
          width='8'
          height='8'
          rx='1'
          stroke='#14142B'
          strokeWidth='2'
        />
      </svg>
    </SvgIcon>
  );
};

export default OverlayBtnTileIcon;
