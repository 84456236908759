import React from 'react';
import './styles.scss';

import { integrationStepsWix } from '../../../OverlayBtn/Components/Wix';
import Box from '../../../../../Components/Atoms/Box';
import StepByStep from '../../../Components/StepsForIntegration';

const LinkBtnStepsWix: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWix} />
    </Box>
  );
};

export default LinkBtnStepsWix;
