import React from 'react';
import SimpleCard from '../../../Components/Molecules/SimpleCard';

const NoIdHomepage = () => {
  return (
    <SimpleCard
      titleTranslation='common'
      title='Oops!'
      typoVariantTitle='h1'
      typoVariantDescription='text-2'
      className='card-width'
    >
      Sorry, no restaurant id was detected. Please go back to your Gastronaut
      profile and make sure you're connected, then click again on the How to
      button.
    </SimpleCard>
  );
};

export default NoIdHomepage;
