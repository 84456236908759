import React from 'react';
import './styles.scss';

import { integrationStepsJim } from '../../../OverlayBtn/Components/Jimdo';
import Box from '../../../../../Components/Atoms/Box';
import StepByStep from '../../../Components/StepsForIntegration';

const LinkBtnStepsJim: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsJim} />
    </Box>
  );
};

export default LinkBtnStepsJim;
