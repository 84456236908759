import React from 'react';
import './styles.scss';

import { integrationStepsWd } from '../../../OverlayBtn/Components/Wordpress/index';
import Box from '../../../../../Components/Atoms/Box';
import StepByStep from '../../../Components/StepsForIntegration';

const LinkStepsWd: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWd} />
    </Box>
  );
};

export default LinkStepsWd;
