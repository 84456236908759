import './App.scss';
import ThemeContextProvider from './Contexts/ThemeContext';

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomepageContainer from './App/Homepage/Screen';
import ClassBtnContainer from './App/IntOptions/ClassToBtn/Screen';
import IframeContainer from './App/IntOptions/Iframe/Screen';
import LinkBtnContainer from './App/IntOptions/LinkBtn/Screen';
import OverlayBtnContainer from './App/IntOptions/OverlayBtn/Screen';
import LanguageContextProvider from './Contexts/LanguageContext';
import NavigationBar from './App/NavigationBar';
import Footer from './App/Footer';
import NoIdHomepageContainer from './App/NoIdHomepage/Screen';

function App() {
  const pathname = window.location.pathname.split('/');
  const restaurantId = pathname[1];

  console.log('*appRestId*', restaurantId);
  return (
    <div className='App'>
      <ThemeContextProvider>
        <LanguageContextProvider>
          <NavigationBar restaurantId={restaurantId} />
          <div className='body-margin'>
            <Switch>
              <Route exact path='/' component={NoIdHomepageContainer} />
              <Route
                exact
                path='/:restaurantId'
                component={HomepageContainer}
              />
              <Route
                exact
                path='/:restaurantId/overlayButton'
                component={OverlayBtnContainer}
              />
              <Route
                exact
                path='/:restaurantId/linkButton'
                component={LinkBtnContainer}
              />
              <Route
                exact
                path='/:restaurantId/iFrame'
                component={IframeContainer}
              />
              <Route
                exact
                path='/:restaurantId/addPluginToButton'
                component={ClassBtnContainer}
              />
            </Switch>
          </div>
          <Footer />
        </LanguageContextProvider>
      </ThemeContextProvider>
    </div>
  );
}

export default App;
