import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

export const integrationStepsWix: IntegrationSteps = [
  {
    number: 1,
    step:
      'Copy the snippet code from your profile or from the box on top of this page.',
  },
  {
    number: 2,
    step: 'Go to your Wix Editor.',
  },
  {
    number: 3,
    step:
      'Click on the plus (+) button Add and choose Embed a block. Stay on the Custom Embed section and choose Embed a widget.',
    img: '/doc-steps-imgs/overlaybtn/wix/step-3.JPG',
  },
  {
    number: 4,
    step:
      'In the text box, write : <div></div>. Those are an opening and its corresponding closing tags. It will create a recipient for your code snippet. Paste the code snippet between the two div tags and then click on Update. The Reservation element should be displayed in the block.',
  },
  {
    number: 5,
    step:
      'You should adjust the size of the block according to the wanted Reservation Plugin size. To test it, click on Preview on the top left of the canva.',
  },
];

const OverlayBtnStepsWix: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWix} />
    </Box>
  );
};

export default OverlayBtnStepsWix;
