import { Size } from './types';

export const classHelper = (arr: any[]) => arr.filter((x) => !!x).join(' ');

export const noop = () => {};

export const sizeHelper = (size: Size | undefined) => {
  switch (size) {
    case 'sm': {
      return 8;
    }
    case 'md': {
      return 16;
    }
    case 'lg': {
      return 24;
    }
    default: {
      return size;
    }
  }
};

export const textAlignToJustifyContent = (
  textAlign: 'right' | 'left' | 'center'
) => {
  switch (textAlign) {
    case 'right': {
      return 'flex-end';
    }
    case 'left': {
      return 'flex-start';
    }
    case 'center': {
      return 'center';
    }
    default:
      return 'flex-start';
  }
};

export const textAlignToPlacement = (
  textAlign: 'right' | 'left' | 'center'
) => {
  switch (textAlign) {
    case 'right': {
      return 'bottom-end';
    }
    case 'left': {
      return 'bottom-start';
    }
    case 'center': {
      return 'bottom';
    }
    default:
      return 'bottom-start';
  }
};

export function copyToClipboard(containerid: string) {
  try {
    if (window.getSelection) {
      // @ts-ignore
      if (window.getSelection().empty) {
        // @ts-ignore
        window.getSelection().empty();
        // @ts-ignore
      } else if (window.getSelection().removeAllRanges) {
        // @ts-ignore
        window.getSelection().removeAllRanges();
      }
      // @ts-ignore
    } else if (document.selection) {
      // @ts-ignore
      document.selection.empty();
    }

    // @ts-ignore
    if (document.selection) {
      var text = document.getElementById(containerid)?.innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
      text && navigator.clipboard.writeText(text)
      // @ts-ignore
      // var range = document.body.createTextRange();
      // range.moveToElementText(document.getElementById(containerid));
      // range.select().createTextRange();
      // document.execCommand('copy');
    } else if (window.getSelection) {
      // var range = document.createRange();
      // range.selectNode(document.getElementById(containerid));
      // // @ts-ignore
      // window.getSelection().addRange(range);
      // document.execCommand('copy');
      // // @ts-ignore
      var text = document.getElementById(containerid)?.innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
      text && navigator.clipboard.writeText(text)
      // document.selection.empty();
    }
  } catch (e) {
    console.error(e);
  }
}
