import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const NoOverlayBtnTileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='3.6665'
          y='2'
          width='18'
          height='20'
          rx='2'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.6665 8H21.6665'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.1665 8V22'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default NoOverlayBtnTileIcon;
