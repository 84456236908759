import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

const integrationStepsWix: IntegrationSteps = [
  {
    number: 1,
    step: `Go to Settings in your Wix site's dashboard.`,
  },
  {
    number: 2,
    step: 'Click the Custom Code tab in the Advanced section.',
    img: '/doc-steps-imgs/classtobtn/wix/steps-1-2.JPG',
  },
  {
    number: 3,
    step: 'Click + Add Custom Code at the top right.',
  },
  {
    number: 4,
    step: 'Paste the code snippet in the text box.',
  },
  {
    number: 5,
    step: 'Enter a name for your code.',
  },
  {
    number: 6,
    step: `Select an option under Add Code to Pages: All pages: This adds the code to all of your site's pages, including any new pages that you create in the future. Choose whether to load the code only once per visit, or on each page your visitor opens. Choose specific pages: Use the drop-down menu to select the relevant pages.`,
  },
  {
    number: 7,
    step:
      'Under Place Code, place your code in the Body End section. Click on Apply.',
  },
  {
    number: 8,
    step:
      'Then go on the pages where you want your buttons to be displayed. Click on the plus (+) button Add and choose Embed a block. Stay on the Custom Embed section and choose Embed a widget.',
  },
  {
    number: 9,
    step:
      'Paste the code for your own button and add the class ‘gastronautbtn’. You can drag the element where you want on the page.',
  },
];

const ClassToBtnStepsWix: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWix} />
    </Box>
  );
};

export default ClassToBtnStepsWix;
