import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';
import SimpleCard from '../../../../../Components/Molecules/SimpleCard';

const integrationStepsWd: IntegrationSteps = [
  {
    number: 1,
    step: 'Go to your WordPress and log into your account.',
  },
  {
    number: 2,
    step: `On the right side select Design and then Theme Editor.`,
  },
  {
    number: 3,
    step: 'Now from the right top side select your current installed theme.',
    img: '/doc-steps-imgs/classtobtn/wordpress/steps-3-4.JPG',
  },
  {
    number: 4,
    step:
      'At the right sidebar you will spot different .php files of your WordPress website. From those files find the footer.php and select that to edit.',
  },
  {
    number: 5,
    step:
      'After selecting the footer.php file, press ctrl+F and search for </body> and where the closing head tag is situated.',
  },
  {
    number: 6,
    step: 'Paste the code snippet right before the body closing tag.',
  },
  {
    number: 7,
    step:
      'Go to the article/page interface where you want the plugIn to be displayed.',
  },
  {
    number: 8,
    step:
      'On top right of the article main text field, you’ll see two tabs Visual and Text. Click on Text to get access to the code structure. If you’re using a version 5.0 or more, stay on the page and simply add a new block integrated HTML.',
    img: '/doc-steps-imgs/overlaybtn/wordpress/step-5.JPG',
  },
  {
    number: 9,
    step:
      'Go through the code to find the button you want to modify and, after button, add class=‘gastronautbtn’. It should look like : <button class=‘gastronautbtn’>...</button>. You can check the result by going back to Visual.',
  },
];

const ClassToBtnStepsWd: any = () => {
  return (
    <Box className='class-to-btn-wd'>
      <StepByStep stepsForIntegration={integrationStepsWd} />
      <SimpleCard
        className='plugin-card'
        title='With a plugin'
        titleTranslation='optionsPage'
        typoVariantTitle='h4'
      >
        An easier way to add the script tag is to use a plugIn. When on the
        dashboard, go to the left sidebar and pick PlugIns, then To install.
        Search, for example, for header and footer and pick the most fitting
        plugIn.
      </SimpleCard>
    </Box>
  );
};

export default ClassToBtnStepsWd;
