import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const LinkBtnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.34304 19.0708C9.24828 22.9761 15.5799 22.9761 19.4852 19.0708C23.3904 15.1656 23.3904 8.83393 19.4852 4.92869C15.5799 1.02344 9.24828 1.02344 5.34304 4.92869'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 9L13.6667 12L11 15'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.5 12L1 12'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default LinkBtnIcon;
