import React, { useState } from 'react';
import './styles.scss';
import PlatformBtns from '../../Components/PlatformBtns';
import SimpleCard from '../../../../Components/Molecules/SimpleCard';
import IframeStepsWd from '../Components/Wordpress';
import IframeStepsWf from '../Components/Webflow';
import IframeStepsWix from '../Components/Wix';
import IframeStepsJim from '../Components/Jimdo';

import SnippetCode from '../../Components/SnippetCode';
import { PlatformList } from '../../../../types/general';
import { useParams } from 'react-router';
import { UrlParams } from '../../../../types/urlRelated';

export type IFrameProps = {
  intOptionId: 'overlayBtn' | 'classToBtn' | 'linkBtn' | 'iframe' | null;
};

const IFrame = ({ intOptionId = 'iframe' }: IFrameProps) => {
  const { restaurantId } = useParams<UrlParams>();

  const [selectedPlatform, setselectedPlatform] = useState<{
    id: PlatformList;
    name: string;
  } | null>(null);

  return (
    <div className='iframe-screen' id='iframePage'>
      {restaurantId !== 'undefined' || restaurantId === undefined ? (
        <>
          <div className='card-max-width'>
            <SimpleCard
              typoVariantTitle='h2'
              typoVariantDescription='text-3'
              className='card-max-width'
              title='iFrame solution'
              titleTranslation='optionsPage'
            >
              This option allows you to display the reservation window directly
              on the page where the snippet is integrated. You can see and test
              below the expected result and copy the snippet code you'll need to
              integrate the button to your website. Please select the platform
              your website is hosted with to access the integration steps.
            </SimpleCard>

            <iframe
              id='iframe-final-result'
              src={`https://reservation.gastronaut.ai/${restaurantId}`}
              width='100%'
              height='100%'
            ></iframe>

            <SnippetCode optionId={intOptionId} />
          </div>

          <PlatformBtns
            className='platform-btns'
            selectedPlatform={selectedPlatform}
            onClick={setselectedPlatform}
          />

          {selectedPlatform?.id === 'wdp' && (
            <div className='margin-top-100'>
              <IframeStepsWd />
            </div>
          )}
          {selectedPlatform?.id === 'wbf' && (
            <div className='margin-top-100'>
              <IframeStepsWf />
            </div>
          )}
          {selectedPlatform?.id === 'wix' && (
            <div className='margin-top-100'>
              <IframeStepsWix />
            </div>
          )}
          {selectedPlatform?.id === 'jim' && (
            <div className='margin-top-100'>
              <IframeStepsJim />
            </div>
          )}
        </>
      ) : (
        <SimpleCard
          typoVariantTitle='h2'
          typoVariantDescription='text-3'
          className='card-max-width'
          title='Oops'
        >
          Sorry, no restaurant id was detected. Please go back to your
          Gastronaut profile and make sure you're connected, then click again on
          the How to button.
        </SimpleCard>
      )}
    </div>
  );
};

export default IFrame;
