import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

export const integrationStepsJim: IntegrationSteps = [
  {
    number: 1,
    step:
      'Copy the snippet code from your profile or from the box on top of this page.',
  },
  {
    number: 2,
    step:
      'Log in to your Jimdo account and choose the desired website. You won’t be able to access these functionalities with a Play pack.',
  },
  {
    number: 3,
    step: 'Click Edit to enter your Jimdo Creator website’s edit mode.',
  },
  {
    number: 4,
    step:
      'Hover over the content area. A box should appear, click on Add Element and choose More elements and Add-ons.',
  },
  {
    number: 5,
    step:
      'Choose the Widget/HTML Element to add it to your website. Paste the snippet code.',
  },
];

const OverlayBtnStepsJim: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsJim} />
    </Box>
  );
};

export default OverlayBtnStepsJim;
