import ListItem from '@material-ui/core/ListItem';
import { ArrowDropDown, ArrowDropUp } from '../../Atoms/Icons';
import Button, { ButtonProps } from '../../Atoms/Button';
import React, { useMemo, useState } from 'react';
import {
  noop,
  textAlignToJustifyContent,
  textAlignToPlacement,
} from '../../../utils/helpers';
import Popover from '../../Atoms/Popover';
import Typography from '../../Atoms/Typography';
import './styles.scss';
import { Translations } from '../../../utils/types';

type Value = string | number | null;

type Index = number;

type Extractor = {
  (currentOption: any, index?: Index): Value;
};

type Option = {
  (params: OptionElementType): React.ReactNode;
};

type OnClick = {
  (): void;
};

type OptionElementType = {
  onClick: OnClick;
  selected: boolean;
  id: Value;
  label: Value;
  justifyContent: string;
};

const optionElementDefault = ({
  onClick,
  selected,
  id,
  label,
  justifyContent,
}: OptionElementType) => (
  <ListItem
    key={id || 'null'}
    style={{ justifyContent }}
    onClick={onClick}
    selected={selected}
    button
  >
    {label}
  </ListItem>
);
const idExtractorDefault = (opt: any) =>
  typeof opt === 'object' && opt !== null ? opt.id : opt;
const labelExtractorDefault = (opt: any) =>
  typeof opt === 'object' && opt !== null ? opt.label : opt;

interface Props {
  options: any[];
  value: Value;
  style?: React.CSSProperties;
  disabled?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  idExtractor?: Extractor;
  labelExtractor?: Extractor;
  onChange: Function;
  buttonProps?: ButtonProps;
  optionElement?: Option;
  linksList?: boolean;
  className?: string;
  translation?: Translations | null;
}

const Dropdown = ({
  options = [],
  value = '',
  textAlign = 'left',
  idExtractor = idExtractorDefault,
  labelExtractor = labelExtractorDefault,
  optionElement = optionElementDefault,
  buttonProps = {},
  disabled = false,
  onChange = noop,
  linksList = false,
  style,
  className,
  translation,
}: Props) => {
  const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);

  const current = useMemo(
    () => options.find((opt, i) => idExtractor(opt, i) === value) || value,
    [idExtractor, value, options]
  );

  const currentLabel = labelExtractor(current);

  const handleOpen = (e: React.SyntheticEvent) =>
    setopen(!disabled ? e.currentTarget : null);

  const handleClose = () => setopen(null);

  const handleChange = (nV: Value) => {
    setopen(null);
    onChange(nV);
  };

  return (
    <div className={className}>
      <Button
        translation={translation}
        disableRipple={true}
        id='dropdown-btn'
        typographyProps={{ variant: 'text-3' }}
        color={'subdued'}
        disabled={disabled}
        style={style}
        variant='transparent'
        {...buttonProps}
        endIcon={(params) =>
          !!open ? <ArrowDropUp {...params} /> : <ArrowDropDown {...params} />
        }
        onClick={handleOpen}
      >
        {String(currentLabel)}
      </Button>
      <Popover
        anchorEl={open}
        open={!!open && !disabled}
        onClose={handleClose}
        placement={textAlignToPlacement(textAlign)}
        width='auto'
        padding='md'
        className='popover'
      >
        {linksList ? (
          <div onClick={handleClose}>
            {options.map((option, id) => (
              <Typography
                key={id}
                block
                className='option-is-link'
                variant='text-3'
              >
                {option}
              </Typography>
            ))}
          </div>
        ) : (
          options.map((option) => {
            let id = idExtractor(option);
            let label = labelExtractor(option);
            let selected = value === id;

            return optionElement({
              onClick: () => handleChange(id),
              id,
              label,
              selected,
              justifyContent: textAlignToJustifyContent(textAlign),
            });
          })
        )}
      </Popover>
    </div>
  );
};

export default Dropdown;
