import React, { createContext } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
// import colors from "utils/colors";

import '../styles/general.scss';
import '../styles/colors.scss';
import '../styles/typography.scss';

const ThemeContextProvider = (props: any) => {
  const theme = createTheme({
    spacing: (factor) => `${0.5 * factor}rem`,
    typography: {
      fontFamily: `"CircularStd", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      h1: {
        fontSize: '3rem',
        fontWeight: 'bold',
        letterSpacing: '-0.25px',
        lineHeight: '110%',
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 'bold',
        letterSpacing: '-0.25px',
        lineHeight: '110%',
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0px',
        lineHeight: '110%',
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        letterSpacing: '0px',
        lineHeight: '120%',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: '0px',
        lineHeight: '120%',
      },
      h6: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        letterSpacing: '0px',
        lineHeight: '120%',
      },
      body1: {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        letterSpacing: '0px',
        lineHeight: '130%',
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 'normal',
        letterSpacing: '0px',
        lineHeight: '140%',
      },
      caption: {
        // text 6
        fontSize: '0.625rem',
        fontWeight: 'bolder',
        letterSpacing: '0px',
        lineHeight: '140%',
      },
      subtitle1: {
        // text 4
        fontSize: '0.875rem',
        fontWeight: 'normal',
        letterSpacing: '0px',
        lineHeight: '140%',
      },
      subtitle2: {
        // text 5
        fontSize: '0.75rem',
        fontWeight: 'normal',
        letterSpacing: '0px',
        lineHeight: '140%',
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeContextProvider;
