import common from './common.json';
import homepage from './homepage.json';
import optionsPage from './optionsPage.json';
import navbar from './navbar.json';
import footer from './footer.json';

const languages = {
  common,
  homepage,
  optionsPage,
  navbar,
  footer,
};

export default languages;
