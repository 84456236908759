import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

const integrationStepsWf: IntegrationSteps = [
  {
    number: 1,
    step:
      'Copy the snippet code from your profile or from the box on top of this page.',
  },
  {
    number: 2,
    step: 'Go to Webflow and log into your account.',
  },
  {
    number: 3,
    step: 'When on the editor, press P to open the pages manager.',
    img: '/doc-steps-imgs/classtobtn/webflow/step-3.JPG',
  },
  {
    number: 4,
    step:
      'On Home, click on the Gear to access the parameters. Scroll down until the Custom Code section.',
  },
  {
    number: 5,
    step:
      'Two text boxes are displayed. Inside the second one, Before </body> tag, paste the code snippet you got from your profile.',
    img: '/doc-steps-imgs/classtobtn/webflow/steps-5-6.JPG',
  },
  {
    number: 6,
    step:
      'Click on Save. The script is added to the bottom of the page! Now we need to add a ‘gastronautbtn’ class to the buttons that should open the Reservation Plugin, to link the script to the buttons.',
  },
  {
    number: 7,
    step:
      'Select the page you need to modify and on the canvas, select the button you want to target.',
  },
  {
    number: 8,
    step:
      'In the left sidebar, make sure you’re in the Brush icon section, and click into the Selector field. Type here ‘gastronautbtn’. It’s done!',
    img: '/doc-steps-imgs/classtobtn/webflow/last-step.JPG',
  },
];

const ClassToBtnIntegrationStepsWf: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWf} />
    </Box>
  );
};

export default ClassToBtnIntegrationStepsWf;
