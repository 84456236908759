import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { integrationStepsWix } from '../../../OverlayBtn/Components/Wix';
import StepByStep from '../../../Components/StepsForIntegration';

const OverlayBtnStepsWix: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWix} />
    </Box>
  );
};

export default OverlayBtnStepsWix;
