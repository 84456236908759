import React from 'react';
import {
  default as MaterialSelect,
  SelectProps,
} from '@material-ui/core/Select';
import InputBase from '../InputBase';
import './styles.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { Translations } from '../../../utils/types';
import Typography from '../Typography';
import { FormControl, InputLabel } from '@material-ui/core';

type Option =
  | string
  | number
  | null
  | {
      id: string | number | null;
      label?: string;
      translation?: null | Translations;
      disabled?: boolean;
    };

const Select = ({
  options = [],
  menuClassName = '',
  children,
  label,
  ...props
}: SelectProps & { options: Option[]; menuClassName?: string } & {
  label?: string | null;
}) => {
  return (
    <div className='select-dropdown'>
      {label ? (
        <FormControl variant='filled' fullWidth style={{ margin: 8 }}>
          <InputLabel style={{ fontSize: '15px' }}>{label}</InputLabel>
          <MaterialSelect
            {...props}
            input={<InputBase className='selectInput' />}
            MenuProps={{ className: `select-menu ${menuClassName}` }}
            style={{ borderRadius: '16px' }}
          >
            {options.map((option) => {
              if (typeof option === 'object' && option !== null) {
                return (
                  <MenuItem
                    className='select-MenuItem'
                    key={option.id}
                    value={option.id ?? ''}
                    disabled={option.disabled || false}
                  >
                    <Typography
                      variant='text-3'
                      translation={option?.translation || null}
                    >
                      {option?.label || option.id}
                    </Typography>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    className='select-MenuItem'
                    key={option}
                    value={option ?? ''}
                  >
                    <Typography variant='text-3' translation={null}>
                      {option}
                    </Typography>
                  </MenuItem>
                );
              }
            })}
          </MaterialSelect>
        </FormControl>
      ) : (
        <MaterialSelect
          {...props}
          input={<InputBase className='selectInput' />}
          MenuProps={{ className: `select-menu ${menuClassName}` }}
        >
          {options.map((option) => {
            if (typeof option === 'object' && option !== null) {
              return (
                <MenuItem
                  className='select-MenuItem menuItem'
                  key={option.id}
                  value={option.id ?? ''}
                  disabled={option.disabled || false}
                >
                  <Typography
                    variant='text-3'
                    translation={option?.translation || null}
                  >
                    {option?.label || option.id}
                  </Typography>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  className='select-MenuItem menuItem'
                  key={option}
                  value={option ?? ''}
                >
                  <Typography variant='text-3' translation={null}>
                    {option}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </MaterialSelect>
      )}
    </div>
  );
};

export default Select;
