import React from 'react';
import './styles.scss';
import Button from '../../../../Components/Atoms/Button';
import Typography from '../../../../Components/Atoms/Typography';
import Box from '../../../../Components/Atoms/Box';
import { classHelper, copyToClipboard } from '../../../../utils/helpers';
import { FileCopy } from '../../../../Components/Atoms/Icons';
import { codeSnippetsType } from '../../../../types/general';
import { useParams } from 'react-router';
import { UrlParams } from '../../../../types/urlRelated';

export type SnippetCodeContainerProps = {
  optionId: 'overlayBtn' | 'classToBtn' | 'linkBtn' | 'iframe' | null;
  className?: string;
  id?: string | undefined;
};

const SnippetCodeContainer = ({
  optionId,
  className,
  id,
}: SnippetCodeContainerProps) => {
  const { restaurantId } = useParams<UrlParams>();
  const classNames = classHelper(['snippet-code', className]);

  const codeSnippets: codeSnippetsType = [
    {
      option: 'overlayBtn',
      codeSnippet: `<script type='text/javascript'
      src='https://api.gastronaut.ai/v03/reservations/plugin/${restaurantId}/overlayWithBtn'
      >
      </script>`,
    },
    {
      option: 'classToBtn',
      codeSnippet: `<script type='text/javascript' src='https://api.gastronaut.ai/v03/reservations/plugin/${restaurantId}/overlayWithoutBtn'
      > 
      </script>`,
    },
    {
      option: 'linkBtn',
      codeSnippet: `<script type='text/javascript' src='https://api.gastronaut.ai/v03/reservations/plugin/${restaurantId}/simpleBtn'
      > 
      </script>`,
    },
    {
      option: 'iframe',
      codeSnippet: `<iframe src='https://reservation.gastronaut.ai/${restaurantId}' style="width: 100%; height: 100%; border: none;"
      > 
      </iframe>`,
    },
  ];

  return (
    <Box className={classNames} id={id}>
      {codeSnippets.map(
        (o, id) =>
          o.option === optionId && (
            <div key={id}>
              <Typography
                id='snippet-as-text'
                className='snippet-as-text'
                block
                variant='text-3'
              >
                {o.codeSnippet}
              </Typography>
              <Button
                className='copy-btn'
                variant='transparent'
                endIcon={() => <FileCopy />}
                typographyProps={{ variant: 'text-3' }}
                onClick={() => copyToClipboard('snippet-as-text')}
              ></Button>
            </div>
          )
      )}
    </Box>
  );
};

export default SnippetCodeContainer;
