import React from 'react';
import ClassToBtn from './ClassToBtn';
import './styles.scss';

const ClassBtn = () => {
  return (
    <ClassToBtn intOptionId="classToBtn" />
  );
};

export default ClassBtn;
