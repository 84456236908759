import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { integrationStepsWf } from '../../../OverlayBtn/Components/Webflow';
import StepByStep from '../../../Components/StepsForIntegration';

const OverlayBtnIntegrationStepsWf: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWf} />
    </Box>
  );
};

export default OverlayBtnIntegrationStepsWf;
