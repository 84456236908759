import React from 'react';
import './styles.scss';
import { Platforms } from '../../../../types/general';
import { PlatformList } from '../../../../types/general';
import { classHelper } from '../../../../utils/helpers';
import Button from '../../../../Components/Atoms/Button';
import Typography from '../../../../Components/Atoms/Typography';

export type PlatformBtnsProps = {
  onClick: React.Dispatch<
    React.SetStateAction<{
      id: PlatformList;
      name: string;
    } | null>
  >;
  selectedPlatform: {
    id: PlatformList;
    name: string;
  } | null;
  className?: string;
  id?: string | undefined;
};

const PlatformBtns = ({
  selectedPlatform,
  onClick,
  className,
  id,
}: PlatformBtnsProps) => {
  const classNames = classHelper(['platform-btns-div', className]);

  return (
    <div className='platform-btns-and-title'>
      <Typography block variant='h4' translation='common'>
        Select your platform
      </Typography>
      <div id={id} className={classNames}>
        {Platforms?.map((opt, id) => (
          <Button
            key={id}
            id={opt.id}
            onClick={() => onClick(opt)}
            className={selectedPlatform?.id === opt.id ? 'isSelected' : ''}
          >
            {opt.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default PlatformBtns;
