export type PlatformList = 'wdp' | 'wbf' | 'wix' | 'jim';

export type PlatformType =
  | {
      id: PlatformList;
      name: string;
    }[]
  | null;

export const Platforms: PlatformType = [
  {
    id: 'wdp',
    name: 'WordPress',
  },
  {
    id: 'wbf',
    name: 'Webflow',
  },
  {
    id: 'wix',
    name: 'Wix',
  },
  {
    id: 'jim',
    name: 'Jimdo',
  },
];

export type IntegrationSteps = {
  number: number;
  step: string;
  img?: string;
}[];

export type codeSnippetsType = {
  option: string;
  codeSnippet: string | React.ReactNode;
}[];
