import React, { useEffect, useState } from 'react';
import './styles.scss';
import PlatformBtns from '../../Components/PlatformBtns';
import SimpleCard from '../../../../Components/Molecules/SimpleCard';
import OverlayBtnStepsWd from '../Components/Wordpress';
import OverlayBtnStepsWf from '../Components/Webflow';
import OverlayBtnStepsWix from '../Components/Wix';
import OverlayBtnStepsJim from '../Components/Jimdo';

import SnippetCode from '../../Components/SnippetCode';
import { PlatformList } from '../../../../types/general';
import { useParams } from 'react-router';
import { UrlParams } from '../../../../types/urlRelated';

export type OverlayBtnProps = {
  intOptionId: 'overlayBtn' | 'classToBtn' | 'linkBtn' | 'iframe' | null;
};

const OverlayBtn = ({ intOptionId = 'overlayBtn' }: OverlayBtnProps) => {
  const { restaurantId } = useParams<UrlParams>();

  const [selectedPlatform, setselectedPlatform] = useState<{
    id: PlatformList;
    name: string;
  } | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://api.gastronaut.ai/v03/reservations/${restaurantId}/overlayWithBtn`;
    script.type = 'text/javascript';
    script.async = true;
    document.getElementById('snippet-div-overlaybtn')?.appendChild(script);
  }, []);

  return (
    <div className='overlay-btn-screen' id='overlayButtonPage'>
      {restaurantId !== 'undefined' || restaurantId === undefined ? (
        <>
          <div className='card-max-width'>
            <SimpleCard
              typoVariantTitle='h2'
              typoVariantDescription='text-3'
              title='Ready to use button'
              titleTranslation='optionsPage'
            >
              This option allows you to integrate a ready-to-use button, styled
              the way you chose in the reservation plugin customization settings
              of your Gastronaut profile. You can see and test below the
              expected result and the snippet code you'll need to integrate the
              button to your website. Please select the platform your website is
              created with to access the integration steps.
            </SimpleCard>

            <div id='snippet-div-overlaybtn'></div>

            <SnippetCode optionId={intOptionId} />
          </div>

          <PlatformBtns
            className='platform-btns'
            selectedPlatform={selectedPlatform}
            onClick={setselectedPlatform}
          />

          {selectedPlatform?.id === 'wdp' && (
            <div className='margin-top-100'>
              <OverlayBtnStepsWd />
            </div>
          )}
          {selectedPlatform?.id === 'wbf' && (
            <div className='margin-top-100'>
              <OverlayBtnStepsWf />
            </div>
          )}
          {selectedPlatform?.id === 'wix' && (
            <div className='margin-top-100'>
              <OverlayBtnStepsWix />
            </div>
          )}
          {selectedPlatform?.id === 'jim' && (
            <div className='margin-top-100'>
              <OverlayBtnStepsJim />
            </div>
          )}
        </>
      ) : (
        <SimpleCard
          typoVariantTitle='h2'
          typoVariantDescription='text-3'
          title='Oops!'
          titleTranslation='common'
          className='card-max-width'
        >
          Sorry, no restaurant id was detected. Please go back to your
          Gastronaut profile and make sure you're connected, then click again on
          the How to button.
        </SimpleCard>
      )}
    </div>
  );
};

export default OverlayBtn;
