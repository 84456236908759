import Typography, { TypographyProps } from '../../Atoms/Typography';
import Box, { BoxProps } from '../../Atoms/Box';
import React from 'react';
// import Divider from '../Atoms/Divider';
import { Variant } from '../../Atoms/Typography';
import { Size, Translations } from '../../../utils/types';
import { classHelper } from '../../../utils/helpers';

import './styles.scss';

export interface SimpleCardProps extends BoxProps {
  title?: string | React.ReactNode | any[];
  hideHeader?: boolean;
  titleTranslation?: Translations;
  titleProps?: TypographyProps;
  headerPadding?: Size;
  titleAlignment?: 'left' | 'center' | 'right';
  headerRight?: null | React.ReactNode;
  lowerHeader?: null | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  boxProps?: BoxProps;
  subHeader?: null | React.ReactNode;
  bodyProps?: BoxProps;
  style?: React.CSSProperties;
  elevation?: number;
  hideBorders?: boolean;
  typoVariantTitle?: Variant;
  typoVariantDescription?: Variant;
}

const SimpleCard: React.FC<SimpleCardProps> = ({
  title = '',
  titleProps,
  hideHeader = false,
  titleTranslation,
  headerRight,
  subHeader,
  titleAlignment = 'left',
  headerPadding = undefined,
  className,
  children,
  bodyProps,
  style,
  elevation = null,
  hideBorders = true,
  typoVariantTitle = 'h5',
  typoVariantDescription = 'text-3',
  ...props
}) => {
  const classNames = classHelper(['simple-card', className]);

  return (
    <Box
      outline={!hideBorders}
      className={classNames}
      elevation={elevation}
      style={style}
      {...props}
    >
      {!hideHeader && (
        <Box
          padding={headerPadding}
          flex
          className='space-between rounded'
          style={{ paddingBottom: !!subHeader ? 8 : 'auto' }}
        >
          {
            <Typography
              variant={typoVariantTitle}
              className='flex-grow'
              {...titleProps}
              translation={titleTranslation}
              textAlign={titleAlignment}
            >
              {title}
            </Typography>
          }
          {headerRight}
        </Box>
      )}
      {/* {subHeader} */}
      <Box padding={0} className='margin-top' {...bodyProps}>
        <Typography
          variant={typoVariantDescription}
          color='subdued'
          translation={titleTranslation}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default SimpleCard;
