import React, { useState } from 'react';
import Box from '../../Atoms/Box';
import Button from '../../Atoms/Button';
import { ArrowBack, ArrowForward } from '../../Atoms/Icons';

import './styles.scss';

export type LightboxProps = {
  lightboxDisplay: boolean;
  setLightBoxDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setImageToShow: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string | undefined;
  imagesArray: (string | undefined)[];
};

const Lightbox = ({
  lightboxDisplay,
  setLightBoxDisplay,
  setImageToShow,
  image,
  imagesArray,
}: LightboxProps) => {
  console.log('im', image);

  // const showImage = (image: string | undefined) => {
  //   // setImageToShow(image);
  //   setLightBoxDisplay(true);
  // };

  const hideLightbox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = (e: any) => {
    e.stopPropagation();
    let currentIndex = imagesArray.indexOf(image);

    const nextImg = imagesArray[currentIndex + 1];
    setImageToShow(nextImg);
  };

  const showPrev = (e: any) => {
    e.stopPropagation();
    let currentIndex = imagesArray.indexOf(image);

    const prevImg = imagesArray[currentIndex - 1];
    setImageToShow(prevImg);
  };

  return (
    <div className='lightbox-component'>
      {lightboxDisplay && (
        <Box className='lightbox' onClick={hideLightbox}>
          <Button
            id='prev-btn'
            onClick={showPrev}
            variant='transparent'
            endIcon={() => <ArrowBack />}
            disabled={imagesArray.indexOf(image) === 0}
          />
          <img src={image} alt='step by step img' />
          <Button
            id='next-btn'
            onClick={showNext}
            variant='transparent'
            endIcon={() => <ArrowForward />}
            disabled={
              imagesArray.indexOf(image) === imagesArray.length - 1
            }
          />
        </Box>
      )}
    </div>
  );
};

export default Lightbox;
