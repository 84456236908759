import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Box from '../../../Components/Atoms/Box';
import IFrameIcon from '../../../Components/Atoms/Icons/IFrameIcon';
import LinkBtnIcon from '../../../Components/Atoms/Icons/LinkBtnIcon';
import NoOverlayBtnTileIcon from '../../../Components/Atoms/Icons/NoOverlayBtnTileIcon';
import OverlayBtnTileIcon from '../../../Components/Atoms/Icons/OverlayBtnTileIcon';
import Typography from '../../../Components/Atoms/Typography';
import SimpleCard from '../../../Components/Molecules/SimpleCard';
import { UrlParams } from '../../../types/urlRelated';
import { PluginOptions } from '../../../utils/types';
import Tile from '../Components/Tiles';
import './styles.scss';

const Homepage = () => {
  const { restaurantId } = useParams<UrlParams>();
  console.log('i', restaurantId);

  const FourPlugInOptions: PluginOptions = [
    {
      title: 'Button with defined style',
      description: 'A button already styled you can integrate in your website',
      icon: <OverlayBtnTileIcon />,
      destination: '/overlayButton',
    },
    {
      title: 'Unstyled button',
      description:
        'Add the ability to open the Reservation Modal to your own button',
      icon: <NoOverlayBtnTileIcon />,
      destination: '/addPluginToButton',
    },
    {
      title: 'Directly on the page',
      description:
        'An iFrame allowing you to display the plugin directly on the webpage',
      icon: <IFrameIcon />,
      destination: '/iFrame',
    },
    {
      title: 'External link',
      description:
        'A styled button opening the reservation interface on a brand new webpage',
      icon: <LinkBtnIcon />,
      destination: '/linkButton',
    },
  ];

  return (
    <div className='homepage-screen'>
      {restaurantId !== 'undefined' || typeof restaurantId === undefined ? (
        <>
          <SimpleCard
            titleTranslation='homepage'
            title='Welcome'
            typoVariantTitle='h2'
            className='card-width'
          >
            You'll find here the documentation on how to integrate the
            Gastronaut reservation plugin on your website. There are four ways
            to do so. You can choose wichever one you want according to the
            expected result. Three of them are buttons, one leading to an
            external reservation page, the others opening a pop up on the page
            on which integrated. The fourth solution displays the plugin inside
            the page, so the customer can interact directly with it. The options
            are listed below. Please choose the one you're interested in to
            access to the documentation.
          </SimpleCard>

          <div className='select-option-margin'>
            <Typography translation='common' variant='text-1'>
              Select your desired integration option
            </Typography>
            <Box className='homepage-tiles'>
              {FourPlugInOptions.map((opt, id) => (
                <Link
                  key={id}
                  className='tile-style'
                  to={`/${restaurantId}${opt.destination}`}
                >
                  <Tile
                    title={opt.title}
                    description={opt.description}
                    iconName={opt.icon}
                  />
                </Link>
              ))}
            </Box>
          </div>
        </>
      ) : (
        <SimpleCard
          titleTranslation='common'
          title='Oops!'
          typoVariantTitle='h1'
          typoVariantDescription='text-2'
          className='card-width'
        >
          Sorry, no restaurant id was detected. Please go back to your
          Gastronaut profile and make sure you're connected, then click again on
          the How to button.
        </SimpleCard>
      )}
    </div>
  );
};

export default Homepage;
