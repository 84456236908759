import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const iFrame = (props: SvgIconProps) => {
  return (
    <SvgIcon>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.3335 9.88014C2.3335 8.92659 2.78681 8.02983 3.55465 7.46442L12.3335 1L21.1123 7.46443C21.8802 8.02983 22.3335 8.92659 22.3335 9.88014V20.5C22.3335 21.8807 21.2142 23 19.8335 23H4.8335C3.45278 23 2.3335 21.8807 2.3335 20.5V9.88014Z'
          stroke='#14142B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default iFrame;
