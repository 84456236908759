import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import StepByStep from '../../../Components/StepsForIntegration';
import { IntegrationSteps } from '../../../../../types/general';

export const integrationStepsJim: IntegrationSteps = [
  {
    number: 1,
    step:
      'Log in to your Jimdo account and choose the desired website. You won’t be able to access these functionalities with a Play pack.',
  },
  {
    number: 2,
    step:
      'Place your own code in the Edit Head section of your Jimdo Creator website. You’ll find this section under Menu > Settings > Edit Head.',
  },
  {
    number: 3,
    step:
      'Create your own individual design using HTML and CSS code. You can add this code in the menu under Design > Advanced > Custom Template.',
  },
];

const ClassToBtnStepsJim: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsJim} />
    </Box>
  );
};

export default ClassToBtnStepsJim;
