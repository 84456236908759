import React, { useEffect, useState } from 'react';
import PlatformBtns from '../../Components/PlatformBtns';
import './styles.scss';
import SimpleCard from '../../../../Components/Molecules/SimpleCard';
import ClassToBtnStepsWd from '../Components/Wordpress';
import ClassToBtnStepsWf from '../Components/Webflow';
import ClassToBtnStepsWix from '../Components/Wix';
import ClassToBtnStepsJim from '../Components/Jimdo';

import SnippetCode from '../../Components/SnippetCode';
import Button from '../../../../Components/Atoms/Button';
import { PlatformList } from '../../../../types/general';
import { useParams } from 'react-router-dom';
import { UrlParams } from '../../../../types/urlRelated';

export type ClassToBtnProps = {
  intOptionId: 'overlayBtn' | 'classToBtn' | 'linkBtn' | 'iframe' | null;
};

const ClassToBtn = ({ intOptionId = 'classToBtn' }: ClassToBtnProps) => {
  const { restaurantId } = useParams<UrlParams>();

  const [selectedPlatform, setselectedPlatform] = useState<{
    id: PlatformList;
    name: string;
  } | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://api.gastronaut.ai/v03/reservations/plugin/${restaurantId}/overlayWithoutBtn`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div id='classToButtonPage' className='class-to-btn-screen'>
      {restaurantId !== 'undefined' || restaurantId === undefined ? (
        <>
          <div className='card-max-width'>
            <SimpleCard
              typoVariantTitle='h2'
              typoVariantDescription='text-3'
              className='card-max-width'
              title='Your own button'
              titleTranslation='optionsPage'
            >
              This option allows you to add the ability to open the reservation
              plugin to the buttons of your website. You won't have to place the
              snippet code where you need it to appear, but in a more general
              way, and then add a class to the button or buttons you want to
              open the reservation overlay. You can see and test below the
              expected result and copy the snippet code you'll need to integrate
              the button to your website. Please select the platform your
              website is hosted with to access the integration steps.
            </SimpleCard>

            <Button
              id='class-to-btn'
              className='gastronautbtn'
              translation='common'
            >
              Sample button
            </Button>

            <SnippetCode optionId={intOptionId} />
          </div>

          <PlatformBtns
            className='platform-btns'
            selectedPlatform={selectedPlatform}
            onClick={setselectedPlatform}
          />

          {selectedPlatform?.id === 'wdp' && (
            <div className='margin-top-100'>
              <ClassToBtnStepsWd />
            </div>
          )}
          {selectedPlatform?.id === 'wbf' && (
            <div className='margin-top-100'>
              <ClassToBtnStepsWf />
            </div>
          )}
          {selectedPlatform?.id === 'wix' && (
            <div className='margin-top-100'>
              <ClassToBtnStepsWix />
            </div>
          )}
          {selectedPlatform?.id === 'jim' && (
            <div className='margin-top-100'>
              <ClassToBtnStepsJim />
            </div>
          )}
        </>
      ) : (
        <SimpleCard
          typoVariantTitle='h2'
          typoVariantDescription='text-3'
          className='card-max-width'
          title='Oops!'
          titleTranslation='common'
        >
          Sorry, no restaurant id was detected. Please go back to your
          Gastronaut profile and make sure you're connected, then click again on
          the How to button.
        </SimpleCard>
      )}
    </div>
  );
};

export default ClassToBtn;
