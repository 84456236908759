import React from 'react';
import './styles.scss';

import { integrationStepsWf } from '../../../OverlayBtn/Components/Webflow';
import Box from '../../../../../Components/Atoms/Box';
import StepByStep from '../../../Components/StepsForIntegration';

const LinkBtnIntegrationStepsWf: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWf} />
    </Box>
  );
};

export default LinkBtnIntegrationStepsWf;
