import React from 'react';
import './styles.scss';

import Box from '../../../../Components/Atoms/Box';
import SimpleCard from '../../../../Components/Molecules/SimpleCard';
import { classHelper } from '../../../../utils/helpers';

export type TileProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  iconName: React.ReactNode;
  className?: string;
  id?: string;
};

const Tile: any = ({
  title = 'Test Title',
  description = 'Test description',
  iconName,
  className,
  id,
}: TileProps) => {
  const classNames = classHelper(['tile', className]);

  return (
    <Box id={id} className={classNames}>
      {iconName}
      <SimpleCard
        title={title}
        className='margin-top-20'
        bodyProps={{ className: 'margin-top-20' }}
        titleTranslation='homepage'
      >
        {description}
      </SimpleCard>
    </Box>
  );
};

export default Tile;
