import React, { useEffect, useState } from 'react';
import PlatformBtns from '../../Components/PlatformBtns';
import SimpleCard from '../../../../Components/Molecules/SimpleCard';
import './styles.scss';
import LinkBtnStepsWd from '../Components/Wordpress';
import LinkBtnStepsWf from '../Components/Webflow';
import LinkBtnStepsWix from '../Components/Wix';
import LinkBtnStepsJim from '../Components/Jimdo';

import { PlatformList } from '../../../../types/general';
import SnippetCode from '../../Components/SnippetCode';
import { useParams } from 'react-router';
import { UrlParams } from '../../../../types/urlRelated';

export type LinkBtnProps = {
  intOptionId: 'overlayBtn' | 'classToBtn' | 'linkBtn' | 'iframe' | null;
};

const LinkBtn = ({ intOptionId = 'linkBtn' }: LinkBtnProps) => {
  const { restaurantId } = useParams<UrlParams>();

  const [selectedPlatform, setselectedPlatform] = useState<{
    id: PlatformList;
    name: string;
  } | null>(null);

  useEffect(() => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://api.gastronaut.ai/v03/reservations/plugin/${restaurantId}/LinkBtn`;
    document.getElementById('snippet-div-linkbtn')?.appendChild(script);
  }, []);

  return (
    <div className='link-btn-screen' id='linkButtonPage'>
      {restaurantId !== 'undefined' || restaurantId === undefined ? (
        <>
          <div>
            <SimpleCard
              typoVariantTitle='h2'
              typoVariantDescription='text-3'
              className='card-max-width'
              title='Link Button'
              titleTranslation='optionsPage'
            >
              This option is a button working as a link, leading to another page
              dedicated to the reservation of your restaurant. Nothing will be
              opened on your website. You can place it wherever you want in your
              page. You can see and test below the expected result and copy the
              snippet code you'll need to integrate the button to your website.
              Please select the platform your website is hosted with to access
              the integration steps.
            </SimpleCard>

            <div id='snippet-div-linkbtn'></div>

            <SnippetCode optionId={intOptionId} />
          </div>

          <PlatformBtns
            className='platform-btns'
            selectedPlatform={selectedPlatform}
            onClick={setselectedPlatform}
          />

          {selectedPlatform?.id === 'wdp' && (
            <div className='margin-top-100'>
              <LinkBtnStepsWd />
            </div>
          )}
          {selectedPlatform?.id === 'wbf' && (
            <div className='margin-top-100'>
              <LinkBtnStepsWf />
            </div>
          )}
          {selectedPlatform?.id === 'wix' && (
            <div className='margin-top-100'>
              <LinkBtnStepsWix />
            </div>
          )}
          {selectedPlatform?.id === 'jim' && (
            <div className='margin-top-100'>
              <LinkBtnStepsJim />
            </div>
          )}
        </>
      ) : (
        <SimpleCard
          typoVariantTitle='h2'
          typoVariantDescription='text-3'
          className='card-max-width'
          title='Oops!'
        >
          Sorry, no restaurant id was detected. Please go back to your
          Gastronaut profile and make sure you're connected, then click again on
          the How to button.
        </SimpleCard>
      )}
    </div>
  );
};

export default LinkBtn;
