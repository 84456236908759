import React, { useState } from 'react';
import './styles.scss';
import Box from '../../../../Components/Atoms/Box';
import Typography from '../../../../Components/Atoms/Typography';
import { IntegrationSteps } from '../../../../types/general';
import Lightbox from '../../../../Components/Molecules/Lightbox';

export type StepByStepProps = {
  stepsForIntegration: IntegrationSteps;
};

const StepByStep: any = ({ stepsForIntegration }: StepByStepProps) => {
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState<string | undefined>('');

  const imagesArray = stepsForIntegration
    .map((opt) => opt.img)
    .filter((img) => typeof img === 'string');

  console.log(imagesArray)

  return (
    <Box className='step-by-step'>
      {stepsForIntegration.map((opt, id) => (
        <Box key={id} className='step-style'>
          <Typography variant='h3' block className='step-number'>
            {opt.number}.
          </Typography>
          <Box fullSize className='text-and-img'>
            <Typography
              className='text'
              translation='optionsPage'
              variant='text-3'
              block
            >
              {opt.step}
            </Typography>
            {opt?.img && (
              <div>
                <img
                  onClick={() => {
                    setImageToShow(opt.img);
                    setLightBoxDisplay(true);
                  }}
                  className='img'
                  src={opt?.img}
                  alt='step img'
                />
              </div>
            )}
          </Box>
        </Box>
      ))}
      <Lightbox
        lightboxDisplay={lightboxDisplay}
        setLightBoxDisplay={setLightBoxDisplay}
        imagesArray={imagesArray}
        image={imageToShow}
        setImageToShow={setImageToShow}
      />
    </Box>
  );
};

export default StepByStep;
