import React from 'react';
import './styles.scss';

import Box from '../../../../../Components/Atoms/Box';
import { IntegrationSteps } from '../../../../../types/general';
import StepByStep from '../../../Components/StepsForIntegration';

export const integrationStepsWd: IntegrationSteps = [
  {
    number: 1,
    step:
      'Copy the snippet code from your profile or from the box on top of this page.',
  },
  {
    number: 2,
    step: 'Go to your WordPress and log into your account.',
  },
  {
    number: 3,
    step:
      'Go to the article/page interface where you want the plugIn to be displayed.',
  },
  {
    number: 4,
    step:
      'On top right of the article main text field, you’ll see two tabs Visual and Text. Click on Text to get access to the code structure. If you’re using a version 5.0 or more, stay on the page and simply add a new block integrated HTML.',
  },
  {
    number: 5,
    step:
      'Go through the code to find the part where you want to insert the iFrame or button snippet code.',
    img: '/doc-steps-imgs/overlaybtn/wordpress/step-5.JPG',
  },
  {
    number: 6,
    step: 'Paste the snippet code. Make sure you’re not cutting through tags.',
  },
  {
    number: 7,
    step:
      'You can check the result by going back to Visual. Depending on the solution you chose, you may need to click on Preview (top right of the editor) to see the actual result.',
  },
];

const OverlayBtnIntegrationStepsWf: any = () => {
  return (
    <Box>
      <StepByStep stepsForIntegration={integrationStepsWd} />
    </Box>
  );
};

export default OverlayBtnIntegrationStepsWf;
