import React, { useContext } from 'react';
import Typography from '../../Components/Atoms/Typography';
import Box from '../../Components/Atoms/Box';
import './styles.scss';
import { Link, NavLink } from 'react-router-dom';
import Select from '../../Components/Atoms/Select';
import { LanguageContext } from '../../Contexts/LanguageContext';
import Dropdown from '../../Components/Molecules/Dropdown';

export type NavigationBarProps = {
  restaurantId: string;
};

const NavigationBar = ({ restaurantId }: NavigationBarProps) => {
  const { language, changeLanguage, LANGUAGES } = useContext(LanguageContext);

  const handleChange = (e: any) => {
    changeLanguage(e.target.value);
  };

  return (
    <Box fullSize className='navigation-bar'>
      <NavLink to={`/${restaurantId}`}>
        <img src='/logo-b-and-w.JPG' alt='gastronaut logo' />
      </NavLink>
      <Typography block className='nav-website-title' translation='navbar' variant='h5'>
        Gastronaut Integration Guidelines
      </Typography>

      <div className='opt-and-lang-dropdowns'>
        <Dropdown
          className='navbar-dropdown'
          translation='navbar'
          linksList
          value='Options'
          onChange={() => {}}
          options={[
            <Link to={`/${restaurantId}/overlayButton`}>
              <Typography
                color='subdued'
                variant='text-3'
                translation='optionsPage'
              >
                Ready to use button
              </Typography>
            </Link>,
            <Link to={`/${restaurantId}/addPluginToButton`}>
              <Typography
                color='subdued'
                variant='text-3'
                translation='optionsPage'
              >
                Your own button
              </Typography>
            </Link>,
            <Link to={`/${restaurantId}/iFrame`}>
              <Typography
                color='subdued'
                variant='text-3'
                translation='optionsPage'
              >
                iFrame solution
              </Typography>
            </Link>,
            <Link to={`/${restaurantId}/linkButton`}>
              <Typography
                color='subdued'
                variant='text-3'
                translation='optionsPage'
              >
                Link button
              </Typography>
            </Link>,
          ]}
        />

        <Select
          className='language-selection'
          value={language}
          onChange={(e: any) => handleChange(e)}
          options={LANGUAGES}
        />
      </div>
    </Box>
  );
};

export default NavigationBar;
